import React from "react"
import { Helmet } from "react-helmet"
import { Link, graphql } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons"
import Footer from "../components/footer/footer"
import Head from "../components/head"

import blogtemplateStyles from "../styles/blogtemplate.module.scss"

export const query = graphql`
  query($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      publishedDate(formatString: "MMMM Do, YYYY")
      body {
        raw
      }
    }
    contentfulAsset {
      fixed {
        height
        src
        width
      }
    }
  }
`

const Blog = props => {
  const options = {
    renderNode: {
      "embedded-asset-block": () => {
        const alt = props.data.contentfulBlogPost.title
        const url = props.data.contentfulAsset.fixed.src
        return <img className={blogtemplateStyles.img} src={url} alt={alt} />
      },
    },
  }

  return (
    <section className={blogtemplateStyles.template}>
      <Helmet>
        <meta
          name="description"
          content="Mo is a front-end developer from Manchester, England who has an eye for design and user experience. 
            My goal is to deliver quality creative solutions across digital platforms and beyond."
        />
      </Helmet>
      <Head title={props.data.contentfulBlogPost.title} />
      <div className={blogtemplateStyles.container}>
        <h1 className={blogtemplateStyles.logo}>
          <Link className={blogtemplateStyles.logoLink} to="/">
            Mo.
          </Link>
        </h1>
        <div className={blogtemplateStyles.back}>
          <Link className={blogtemplateStyles.link} to="/blog">
            <FontAwesomeIcon icon={faLongArrowAltLeft} /> Back to Posts
          </Link>
        </div>
        <h1 className={blogtemplateStyles.title}>
          {props.data.contentfulBlogPost.title}
        </h1>
        <p className={blogtemplateStyles.date}>
          {props.data.contentfulBlogPost.publishedDate}
        </p>
        <div className={blogtemplateStyles.body}>
          {renderRichText(props.data.contentfulBlogPost.body, options)}
        </div>
        <div className={blogtemplateStyles.back}>
          <Link className={blogtemplateStyles.link} to="/blog">
            <FontAwesomeIcon icon={faLongArrowAltLeft} /> Back to Posts
          </Link>
        </div>
      </div>
      <Footer />
    </section>
  )
}

export default Blog
